<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="다국어 메시지데이터 일괄업로드" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="다국어 메시지데이터 양식 다운로드" icon="download" @btnClicked="downLoadTemplate"/>
            <c-btn 
              v-if="editable" 
              label="LBLSAVEALL" 
              icon="save"
              @btnClicked="excelUpload"/>
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-excel-upload 
              :editable="editable"
              :excelUploadInfo="excelUploadInfo"
              :isTemplateDown="false"
            />
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'msg-upload',
  data() {
    return {
      editable: true,
      excelUploadInfo: {
        rowKeys: ['msgCd'], // 데이터들의 키 속성값
        taskClassCd: 'LANG_FULL_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            required: true,
            name: 'mstCd',
            field: 'mstCd',
            label: '상위코드',
            align: 'center',
            type: 'readonly',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'msgCd',
            field: 'msgCd',
            label: '코드',
            align: 'center',
            type: 'readonly',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'msgVal',
            field: 'msgVal',
            label: '한국어',
            align: 'left',
            type: 'text',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'msgValEn',
            field: 'msgValEn',
            label: '영어',
            align: 'left',
            type: 'text',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'msgValJa',
            field: 'msgValJa',
            label: '일본어',
            align: 'left',
            type: 'text',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'msgValZhCn',
            field: 'msgValZhCn',
            label: '중국어간체',
            align: 'left',
            type: 'text',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'msgValZhTw',
            field: 'msgValZhTw',
            label: '중국어번체',
            align: 'left',
            type: 'text',
            style: 'width: 200px',
            sortable: false,
          },
        ]],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    downLoadTemplate() {
      let thisVue = this;
      this.$http.url = transactionConfig.sys.msg.excel.down.url;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
          var blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "다국어메시지업로드양식.xlsx");
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "다국어메시지업로드양식.xlsx";
            link.click();
          }
      },);
    },
    excelUpload() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }
        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                let _result = this.excelUploadInfo.data[0];
                if (_result && _result.length > 0) {
                  let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
                  this.$_.forEach(s_data, item => {
                    item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
                    item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
                    item.editFlag = 'U';
                  })
                  this.$http.url = transactionConfig.sys.msg.excel.down.url;
                  this.$http.type = 'POST';
                  this.$http.param = s_data;
                  this.$http.request(() => {
                    window.getApp.$emit('APP_REQUEST_SUCCESS');
                  },);
                }
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '데이터를 적용하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                let _result = this.excelUploadInfo.data[0];
                if (_result && _result.length > 0) {
                  let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
                  this.$_.forEach(s_data, item => {
                    item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
                    item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
                    item.editFlag = 'U';
                  })
                  this.$http.url = transactionConfig.sys.msg.excel.down.url;
                  this.$http.type = 'POST';
                  this.$http.param = s_data;
                  this.$http.request(() => {
                    window.getApp.$emit('APP_REQUEST_SUCCESS');
                  },);
                }
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
